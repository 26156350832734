import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BibliographyPage, {
  BibliographyPageQuery,
} from "../components/BibliographyPage";

const BibliographyPageHOC: React.FC<{}> = () => {
  const query = useStaticQuery<BibliographyPageQuery>(graphql`
    query AllBib {
      tabletop: file(
        sourceInstanceName: { eq: "snippets" }
        name: { eq: "bib-section-tabletop" }
      ) {
        id
        childMdx {
          frontmatter {
            title
            section
          }
          body
        }
      }
      speaking: file(
        sourceInstanceName: { eq: "snippets" }
        name: { eq: "bib-section-speaking" }
      ) {
        id
        childMdx {
          frontmatter {
            title
            section
          }
          body
        }
      }
      intro: file(
        sourceInstanceName: { eq: "snippets" }
        name: { eq: "bib-intro" }
      ) {
        id
        childMdx {
          frontmatter {
            title
          }
          body
        }
      }
      allFile(
        sort: { fields: childMdx___frontmatter___date, order: DESC }
        filter: { sourceInstanceName: { in: ["bibliography"] } }
      ) {
        nodes {
          id
          childMdx {
            frontmatter {
              title
              date
              author
              credit
              url
              image
              section
            }
            body
          }
        }
      }
    }
  `);
  return <BibliographyPage query={query} />;
};

export default BibliographyPageHOC;
