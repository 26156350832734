import React from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import Monogram from "../components/Monogram";
import Header from "../components/Header";
import {
  BibliographyResult,
  BibliographyResultItem,
  Snippet,
  MDXFile,
} from "../graphql";
import MediaEntry, { MediaEntryProps } from "../components/MediaEntry";
import MarkdownContent from "../components/MarkdownContent";
import { H2 } from "../components/MarkdownElements";

interface Section {
  body: string;
  title: string;
  items: MediaEntryProps[];
}
type BibliographyItemsMap = Map<string, MediaEntryProps[]>;

export function reduceItems(
  items: BibliographyResultItem[]
): BibliographyItemsMap {
  return items.reduce((init, item) => {
    const {
      body: html,
      frontmatter: { section, ...mediaEntryProps },
    } = item.childMdx;
    // TODO: how do we catch this early?
    if (section) {
      if (!init.has(section)) {
        init.set(section, []);
      }
      init.get(section)?.push({
        html,
        ...mediaEntryProps,
      });
    }
    return init;
  }, new Map());
}

export function reduceSections(
  sections: MDXFile<Snippet>[],
  items: BibliographyItemsMap
): Section[] {
  return sections.reduce((acc: Section[], snippet) => {
    const {
      body,
      frontmatter: { section, title },
    } = snippet.childMdx;
    if (section && title && items.has(section)) {
      acc.push({
        body,
        title,
        items: items.get(section) || [],
      });
    }
    return acc;
  }, []);
}

export type BibliographyPageQuery = BibliographyResult & {
  tabletop: MDXFile<Snippet>;
  speaking: MDXFile<Snippet>;
  intro: MDXFile<Snippet>;
};

/**
 * Functional component representing the entire bibliography page
 */
const BibliographyPage: React.FC<{ query: BibliographyPageQuery }> = ({
  query,
}) => {
  const { allFile, intro, ...snippets } = query;
  const allItems = reduceItems(allFile.nodes);
  const sections = reduceSections(Object.values(snippets), allItems);
  const {
    body: introBody,
    frontmatter: { title: pageTitle },
  } = intro.childMdx;
  return (
    <Layout>
      <Head title={pageTitle} />
      <Header title={pageTitle} />
      <MarkdownContent html={introBody} brief={true} />
      {sections.map(({ title, body, items }, i) => (
        <section key={i}>
          <H2>{title}</H2>
          <MarkdownContent html={body} brief={true} />
          {items.map((item, j) => (
            <MediaEntry key={j} {...item} />
          ))}
        </section>
      ))}
      <Monogram footer={true} />
    </Layout>
  );
};

export default BibliographyPage;
